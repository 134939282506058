import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from "sweetalert2";
import RichTextMarkdown from '../utils/RichTextMarkdown';
import { validateEmail} from 'myshared';
import {detectHowManyCodesEntered, isEmptyHtmlEntity} from "../utils/HelpfulFunction";
const google = window.google;
let geocoder = new google.maps.Geocoder();
const isValidDomain = require('is-valid-domain');

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tenantVariables: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidMount(){
        this.tenantVariablesRef = base.syncDoc(`tenantVariables/default`, {
            context: this,
            state: 'tenantVariables',
            then(){
                const tenantVariables = this.state.tenantVariables;
                let advanced = false;
                if(tenantVariables.doNotCollectEmail || tenantVariables.sanitizeEmails || tenantVariables.allowList || tenantVariables.blockList || tenantVariables.collectDistance || tenantVariables.noMandatoryTermsAndConditions || (tenantVariables.doNotVerifyAmericanZipCode && tenantVariables.collectZipCode) || tenantVariables.collectYesNoQuestion || tenantVariables.keepFansLoggedIn || tenantVariables.gdprCompliance || tenantVariables.collectCustomDropdown){
                    advanced = true;
                }
                if(tenantVariables){
                    let notAcceptableLocationMessage = tenantVariables.notAcceptableLocationMessage;
                    let yesNoQuestionText = tenantVariables.yesNoQuestionText;
                    let promotionTextTwo = tenantVariables.promotionTextTwo;
                    let promotionText = tenantVariables.promotionText;
                    let blockedZipCodesTextMessage = tenantVariables.blockedZipCodesTextMessage;
                    let gdprComplianceMessage = tenantVariables.gdprComplianceMessage;
                    this.setState({
                        customDropdownOptions: tenantVariables.customDropdownOptions,
                        acceptableDistance: tenantVariables.acceptableDistance,
                        locationPermissionsBody: tenantVariables.locationPermissionsBody,
                        locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                        gdprComplianceHeader: tenantVariables.gdprComplianceHeader,
                        formattedAddress: tenantVariables.formattedAddress,
                        notAcceptableLocationMessage: notAcceptableLocationMessage,
                        yesNoQuestionText: yesNoQuestionText,
                        notAcceptableLocationHeader: tenantVariables.notAcceptableLocationHeader,
                        canPlayOutside: tenantVariables.canPlayOutside,
                        allowList: tenantVariables.allowList,
                        blockList: tenantVariables.blockList,
                        promotionText: promotionText,
                        promotionTextTwo: promotionTextTwo,
                        blockedZipCodesTextMessage: blockedZipCodesTextMessage,
                        blockedZipCodes: tenantVariables.blockedZipCodes,
                        gdprComplianceMessage: gdprComplianceMessage,
                        advanced: advanced
                    })
                }
            }
        });
        this.setState({
            loading:false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleTextChange(evt){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value
        console.log(evt.target.name, evt.target.value)
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    selectOption(button){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        tenantVariablesCopy[button] = !tenantVariablesCopy[button]
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    updateQuestionText(){
        const tenantVariables = this.state.tenantVariables || {};
        let yesNoQuestionText = this.state.yesNoQuestionText;
        if(yesNoQuestionText === "<p><br></p>"){
            yesNoQuestionText = "";
        }
        tenantVariables['yesNoQuestionText'] = yesNoQuestionText;

        this.setState({
            tenantVariables:  tenantVariables
        }, () => {
            swal({
                title: 'Updates Successful',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
        });
    }

    updateBlockedZips(){
        const tenantVariables = this.state.tenantVariables || {};
        tenantVariables['blockedZipCodes'] = this.state.blockedZipCodes;
        this.setState({
            tenantVariables:  tenantVariables,
            loading:true
        }, () => {
            swal({
                title: 'Update Successful',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            })
            this.setState({loading:false});
        });
    }

    updateDistance(){
        const tenantVariables = this.state.tenantVariables || {};
        if(!this.state.acceptableDistance || !this.state.formattedAddress){
            swal({
                title: 'Hold on!',
                text: "Please make sure all the form fields are filled out",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(this.state.acceptableDistance <= 0){
            swal({
                title: 'Hold on!',
                text: "Please make sure distance is greater than 0",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const canPlayOutside = this.state.canPlayOutside || false;
        this.setState({loading:true})
        const vm = this;
        let notAcceptableLocationMessage = vm.state.notAcceptableLocationMessage ? vm.state.notAcceptableLocationMessage.toString('html') : "";
        if(notAcceptableLocationMessage === "<p><br></p>"){
            notAcceptableLocationMessage = "";
        }
        geocoder.geocode({'address':this.state.formattedAddress}, function(results, status) {
            if (status === 'OK') {
                if(results.length === 1){
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    tenantVariablesCopy["notAcceptableLocationMessage"] = notAcceptableLocationMessage;
                    tenantVariablesCopy["notAcceptableLocationHeader"] = vm.state.notAcceptableLocationHeader || "";
                    tenantVariablesCopy["canPlayOutside"] = canPlayOutside;
                    vm.setState({
                        tenantVariables:  tenantVariablesCopy,
                        loading: false
                    });
                    swal({
                        title: 'Updates Successful',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    vm.setState({loading:false})
                    swal({
                        title: 'Hold on!',
                        text: "Too many locations have that address! Add more detail to get only 1 address",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                vm.setState({loading:false})
                swal({
                    title: 'Hold on!',
                    text: 'Finding address was not successful because ' + status,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    updateList(listToUpdate){
        const listName = listToUpdate + 'List';
        let listToSave = this.state[listName];
        listToSave = listToSave.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if(listToSave){
            const splitList = listToSave.split(/[ ,\s]+/);
            for(const splitListIndex in splitList) {
                const splitItem = splitList[splitListIndex];
                let valid;
                if (splitItem.indexOf('@') === -1) {
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if (!valid) {
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        base.updateDoc(`tenantVariables/default`, {[listName]: rejoinedCleanedDomainsEmails})
            .then(() => {
                swal({
                    title: 'List Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            }).catch(err => {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    handleRichTextChange = (name, text) => {
        this.setState({
            [name]: text
        })
    }

    updateText(variable){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let textToUpdate = this.state[variable];
        if(isEmptyHtmlEntity(textToUpdate)){
            textToUpdate = "";
        }
        tenantVariablesCopy[variable] = textToUpdate;
        this.setState({
            tenantVariables:  tenantVariablesCopy,
            loading: false
        });
        swal({
            title: 'Updates Successful',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    async updateCustomDropdownOptions() {
        const returnedArray = detectHowManyCodesEntered(this.state.customDropdownOptions, false, true);
        const responseToAlert = await swal({
            title: 'Save Dropdown Options?',
            text: 'Are you sure you want to do this?  We have detected ' + returnedArray.length.toString() + ' potential responses',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
        });
        if (responseToAlert.value) {
            base.updateDoc(`tenantVariables/default`, {
                customDropdownOptions: this.state.customDropdownOptions
            }).then(() => {
                swal({
                    title: 'Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                });
            }).catch(err => {
                swal({
                    title: 'There was some error!',
                    text: 'Try again, and if the problem persists, try logging out and logging back in.',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
        }
    }

    render() {
        const variables = this.state.tenantVariables || {}
        const collectName = variables.collectName;
        const collectYesNoQuestion = variables.collectYesNoQuestion;
        const nameInEmail = variables.nameInEmail;
        const noMandatoryTermsAndConditions = variables.noMandatoryTermsAndConditions;
        const collectZipCode = variables.collectZipCode;
        const collectFullAddress = variables.collectFullAddress;
        const promotionText = this.state.promotionText;
        const promotionTextTwo = this.state.promotionTextTwo;
        const collectOptIn = variables.collectOptIn;
        const collectOptInTwo = variables.collectOptInTwo;
        const notDefaultCheckedOptIn = variables.notDefaultCheckedOptIn;
        const collectOptInTwoNotDefaultCheck = variables.collectOptInTwoNotDefaultCheck;
        const collectBirthday = variables.collectBirthday;
        const collectPhoneNumber = variables.collectPhoneNumber;
        const formBirthday = variables.formBirthday;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const sanitizeEmails = variables.sanitizeEmails;
        const collectDistance = variables.collectDistance;
        const collectCountry = variables.collectCountry;
        const gdprCompliance = variables.gdprCompliance;
        const canPlayOutside = this.state.canPlayOutside;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const doNotVerifyAmericanZipCode = variables.doNotVerifyAmericanZipCode;
        const verifyCanadianZipCode = variables.verifyCanadianZipCode;
        let formattedAddress = this.state.formattedAddress;
        const keepFansLoggedIn = variables.keepFansLoggedIn;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage;
        const yesNoQuestionText = this.state.yesNoQuestionText;
        const blockedZipCodesTextMessage = this.state.blockedZipCodesTextMessage;
        const gdprComplianceMessage = this.state.gdprComplianceMessage;
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;
        const blockedZipCodes = this.state.blockedZipCodes;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const collectCustomDropdown = variables.collectCustomDropdown;
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    {!isMlbApp ?
                        <div className="container-out container-left-rules" style={{float: "left", width: "50%"}}>
                            <div className="admin-form-box">
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a MANDATORY name field to the login flow</p>
                                    <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={() => this.selectOption("collectName")}/>
                                    <div style={{display:collectName ? "":"none", margin: 5}}>
                                        <div className="form-group">
                                            <label htmlFor="collectNamePlaceHolderText">Placeholder Text</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the placeholder text you want to appear in the input box</p>
                                            <input placeholder="First and Last" type="text" id="collectNamePlaceHolderText" name="collectNamePlaceHolderText" className="form-control" value={variables.collectNamePlaceHolderText} onChange={this.handleTextChange}/>
                                        </div>
                                    </div>
                                </div>
                                {collectName &&
                                    <div className="form-group">
                                        <label htmlFor="rulesAndRegsText">Include Name In Email</label>
                                        <br/>
                                        <input type="checkbox" checked={nameInEmail} id="nameInEmail" name="nameInEmail" onClick={() => this.selectOption("nameInEmail")}/>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectOptIn">Collect ZipCode</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a MANDATORY zip code field to the login flow</p>
                                    <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={() => this.selectOption("collectZipCode")}/>
                                    <div className="form-group" style={{display:collectZipCode ? "":"none"}}>
                                        <label htmlFor="collectNamePlaceHolderText">Placeholder Text</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the placeholder text you want to appear in the input box</p>
                                        <input placeholder="5 Digit Zip Code" type="text" id="collectZipCodePlaceHolderText" name="collectZipCodePlaceHolderText" className="form-control" value={variables.collectZipCodePlaceHolderText} onChange={this.handleTextChange}/>
                                    </div>
                                    <br/>
                                    <span style={{display: collectZipCode ? 'block' : 'none' }}>
                                    <div>
                                      <textarea id="blockedZipCodes" name="blockedZipCodes" value={blockedZipCodes} onChange={this.handleChange}/>
                                    </div>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateBlockedZips()}><span className="fa fa-arrow-circle-o-up"/> Update Blocked Zip Codes</button>
                                </span>
                                    {collectZipCode && blockedZipCodes && blockedZipCodes.length > 0 &&
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="blockedZipCodesTextHeader">Blocked Zip Header:</label>
                                                <input id="blockedZipCodesTextHeader" name="blockedZipCodesTextHeader" type="text" className="form-control" value={this.state.tenantVariables.blockedZipCodesTextHeader} onChange={this.handleTextChange} placeholder={"Sorry!"}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="blockedZipCodesTextMessage">Blocked Zip Message:</label>
                                                <RichTextMarkdown
                                                    placeholder="You are outside of the play area"
                                                    form={{ setFieldValue: this.handleRichTextChange }}
                                                    field={{ name: 'blockedZipCodesTextMessage', value: blockedZipCodesTextMessage }}
                                                    handleChange={(newValue) => this.handleRichTextChange("blockedZipCodesTextMessage", newValue)}
                                                />
                                            </div>
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateText("blockedZipCodesTextMessage")}><span className="fa fa-arrow-circle-o-up"/> Update Blocked Zip Message</button>
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a MANDATORY phone number field to the login flow</p>
                                    <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber" name="collectPhoneNumber" onClick={() => this.selectOption("collectPhoneNumber")}/>
                                    <div className="form-group" style={{display:collectPhoneNumber ? "":"none"}}>
                                        <label htmlFor="collectPhoneNumberPlaceHolderText">Placeholder Text</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the placeholder text you want to appear in the input box</p>
                                        <input placeholder="Phone Number" type="text" id="collectPhoneNumberPlaceHolderText" name="collectPhoneNumberPlaceHolderText" className="form-control" value={variables.collectPhoneNumberPlaceHolderText} onChange={this.handleTextChange}/>
                                        <label htmlFor="collectPhoneNumberDefualtCountryCode">Default Country Code</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the default country code</p>
                                        <input placeholder="Default Contry Code" type="text" id="collectPhoneNumberDefualtCountryCode" name="collectPhoneNumberDefualtCountryCode" className="form-control" value={variables.collectPhoneNumberDefualtCountryCode} onChange={this.handleTextChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectFullAddress">Collect Full Address</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY address field to the login flow</p>
                                    <input type="checkbox" checked={collectFullAddress} id="collectFullAddress" name="collectFullAddress" onClick={()=> this.selectOption("collectFullAddress")}/>
                                    <div style={{display:collectFullAddress ? "":"none", margin: 5}}>
                                        <div className="form-group">
                                            <label htmlFor="fullAddressPlaceHolderText">Placeholder Text</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the placeholder text you want to appear in the input box</p>
                                            <input placeholder="Mailing Address" type="text" id="fullAddressPlaceHolderText" name="fullAddressPlaceHolderText" className="form-control" value={variables.fullAddressPlaceHolderText} onChange={this.handleTextChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectBirthday">Collect Birthday</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a MANDATORY birthday input field</p>
                                    <input type="checkbox" checked={collectBirthday} id="collectBirthday" name="collectBirthday" onClick={() => this.selectOption("collectBirthday")}/>
                                    <div style={{display: collectBirthday ? "" : "none", margin: 5}}>
                                        <div className="form-group" style={{display: collectBirthday ? "" : "none"}}>
                                            <label htmlFor="allowedAge">Age</label>
                                            <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Enter an age that the user must be above to play</p>
                                            <input type="number" id="allowedAge" name="allowedAge" className="form-control" value={variables.allowedAge} onChange={this.handleTextChange}/>
                                        </div>
                                        <label className="radio-inline" style={{marginRight: 5}}><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={formBirthday}/> In Form Birthday Input</label>
                                        <label className="radio-inline"><input type="radio" name="formBirthday" id="formBirthday" onChange={() => this.selectOption("formBirthday")} checked={!formBirthday}/> Page Birthday Input</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectOptIn">Turn on Optional Opt-In</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={() => this.selectOption("collectOptIn")}/>
                                </div>
                                {collectOptIn &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="notDefaultCheckedOptIn">Turn Off Default Checked Opt-In</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This will default the opt-in check box to be UNCHECKED</p>
                                            <input type="checkbox" checked={notDefaultCheckedOptIn} id="notDefaultCheckedOptIn" name="notDefaultCheckedOptIn" onClick={()=> this.selectOption("notDefaultCheckedOptIn")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="promotionText">Opt-In Text</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextMarkdown
                                                placeholder="Opt in to our sweet offer"
                                                form={{ setFieldValue: this.handleRichTextChange }}
                                                field={{ name: 'promotionText', value: promotionText }}
                                                handleChange={(newValue) => this.handleRichTextChange("promotionText", newValue)}
                                            />
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateText("promotionText")}><span className="fa fa-arrow-circle-o-up"/> Update Promotion Text</button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                            <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={()=> this.selectOption("collectOptInTwo")}/>
                                        </div>
                                        {collectOptInTwo &&
                                            <>
                                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                                <RichTextMarkdown
                                                    placeholder="Opt in to our sweet offer"
                                                    form={{ setFieldValue: this.handleRichTextChange }}
                                                    field={{ name: 'promotionTextTwo', value: promotionTextTwo }}
                                                    handleChange={(newValue) => this.handleRichTextChange("promotionTextTwo", newValue)}
                                                />
                                                <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateText("promotionTextTwo")}><span className="fa fa-arrow-circle-o-up"/> Update Promotion Text Two</button>
                                                <div className="form-group">
                                                    <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to have the Opt-In field not default checked</p>
                                                    <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={()=> this.selectOption("collectOptInTwoNotDefaultCheck")}/>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                <div className="form-group">
                                    <label htmlFor="collectCountry">Collect Country</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a MANDATORY country drop down field to the login flow</p>
                                    <input type="checkbox" checked={collectCountry} id="collectCountry" name="collectCountry" onClick={() => this.selectOption("collectCountry")}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced"
                                           onChange={this.handleChange}/>
                                </div>
                                <div style={{display: this.state.advanced ? "" : "none"}}>
                                    <div className="form-group">
                                        <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box
                                            to add MANDATORY verification of distance from stadium</p>
                                        <input type="checkbox" checked={collectDistance} id="collectDistance"
                                               name="collectDistance" onClick={() => this.selectOption("collectDistance")}/>
                                        <span style={{display: collectDistance ? 'block' : 'none'}}>
                              <label htmlFor="formattedAddress" style={{marginTop: 5, marginBottom: 0}}>Address</label>
                              <br/>
                              <input className="form-control" type="text" id="formattedAddress" name="formattedAddress"
                                     value={formattedAddress} onChange={this.handleChange} placeholder="Address"/>
                              <br/>
                              <label htmlFor="locationPermissionsHeader" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Header</label>
                              <br/>
                              <input className="form-control" type="text" id="locationPermissionsHeader"
                                     name="locationPermissionsHeader" value={locationPermissionsHeader}
                                     onChange={this.handleChange} placeholder="Location Permissions Header"/>
                              <br/>
                              <label htmlFor="locationPermissionsBody" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Body</label>
                              <br/>
                              <textarea className="form-control" id="locationPermissionsBody"
                                        name="locationPermissionsBody" value={locationPermissionsBody}
                                        onChange={this.handleChange} placeholder="Location Permissions Body"/>
                              <br/>
                              <label htmlFor="notAcceptableLocationHeader" style={{marginTop: 5, marginBottom: 0}}>Not Acceptable Location Header</label>
                              <br/>
                              <input className="form-control" type="text" id="notAcceptableLocationHeader"
                                     name="notAcceptableLocationHeader" value={notAcceptableLocationHeader}
                                     onChange={this.handleChange} placeholder="Not Acceptable Location Header"/>
                              <br/>
                              <label htmlFor="notAcceptableLocationMessage" style={{marginTop: 5, marginBottom: 0}}>Not Acceptable Location Message</label>
                              <br/>
                              <RichTextMarkdown
                                  placeholder="Not Acceptable Location Message"
                                  form={{ setFieldValue: this.handleRichTextChange }}
                                  field={{ name: 'notAcceptableLocationMessage', value: notAcceptableLocationMessage }}
                                  handleChange={(newValue) => this.handleRichTextChange("notAcceptableLocationMessage", newValue)}
                              />
                              <br/>
                              <label htmlFor="acceptableDistance" style={{marginTop: 5, marginBottom: 0}}>Distance (Miles)</label> <input
                                            type="checkbox" onClick={() => this.setState({canPlayOutside: false})}
                                            checked={!canPlayOutside}/> Fans Can Play Within <input
                                            onClick={() => this.setState({canPlayOutside: true})} type="checkbox"
                                            checked={canPlayOutside}/> Fans Can Play Outside
                              <br/>
                              <input className="form-control" type="number" id="acceptableDistance" step="0.1"
                                     name="acceptableDistance" min="0" value={acceptableDistance}
                                     onChange={this.handleChange} placeholder=""/>
                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}}
                                    onClick={() => this.updateDistance()}><span className="fa fa-arrow-circle-o-up"/> Update Distance Variables</button></span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box
                                            to remove all email collection from the game</p>
                                        <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail"
                                               name="doNotCollectEmail"
                                               onClick={() => this.selectOption("doNotCollectEmail")}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And
                                            Conditions</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box
                                            to remove the MANDATORY confirm rules and regs checkbox<br/>Rules and Regs Text
                                            and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs
                                                Tab</u></a></p>
                                        <input type="checkbox" checked={noMandatoryTermsAndConditions}
                                               id="noMandatoryTermsAndConditions" name="noMandatoryTermsAndConditions"
                                               onClick={() => this.selectOption("noMandatoryTermsAndConditions")}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="sanitizeEmails">Sanitize Emails</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box
                                            to map accounts with the same sanitized email to the same account<br/>(could
                                            lead to issues with users having the same email address)</p>
                                        <input type="checkbox" checked={sanitizeEmails} id="sanitizeEmails"
                                               name="sanitizeEmails" onClick={() => this.selectOption("sanitizeEmails")}/>
                                    </div>
                                    {collectZipCode &&
                                        <div className="form-group">
                                            <label htmlFor="doNotVerifyAmericanZipCode">Do Not Verify American Zip
                                                Code?</label>
                                            <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Do not
                                                verify American Zip Code. Check this only if the zip codes you are
                                                collecting are outside the U.S.</p>
                                            <input type="checkbox" checked={doNotVerifyAmericanZipCode} id="doNotVerifyAmericanZipCode" name="doNotVerifyAmericanZipCode" onClick={() => this.selectOption("doNotVerifyAmericanZipCode")}/>
                                        </div>
                                    }
                                    {collectZipCode && doNotVerifyAmericanZipCode &&
                                        <div className="form-group">
                                            <label htmlFor="verifyCanadianZipCode">Verify Canadian Zip Code?</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Verify Canadian Zip Code. Check this only if the zip codes you are collecting are from Canada</p>
                                            <input type="checkbox" checked={verifyCanadianZipCode} id="verifyCanadianZipCode" name="verifyCanadianZipCode" onClick={()=> this.selectOption("verifyCanadianZipCode")}/>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="blockList">Block List Emails/Domains</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Enter any
                                            emails or domains that should be blocked from logging in.</p>
                                        <textarea className="form-control" id="blockList" name="blockList"
                                                  value={this.state.blockList} onChange={this.handleChange}/>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}}
                                                onClick={() => this.updateList('block')}><span
                                            className="fa fa-arrow-circle-o-up"/> Update Block List
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="allowList">Allow List Emails/Domains</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Enter any
                                            emails or domains that should be allowed to login, all other emails/domains will
                                            be blocked. This will SUPERSEDE any emails/domains on the block list and let
                                            them play</p>
                                        <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange}/>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateList('allow')}>
                                            <span className="fa fa-arrow-circle-o-up"/> Update Allow List
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="keepFansLoggedIn">Keep Fans Logged In?</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to keep fans logged in so they will not have to keep filling in the login form</p>
                                        <input type="checkbox" checked={keepFansLoggedIn} id="keepFansLoggedIn" name="keepFansLoggedIn" onClick={()=> this.selectOption("keepFansLoggedIn")}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="collectYesNoQuestion">Collect Yes Or No Question</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a Yes No Question To Your Login Form</p>
                                        <input type="checkbox" checked={collectYesNoQuestion} id="collectYesNoQuestion" name="collectYesNoQuestion" onClick={() => this.selectOption("collectYesNoQuestion")}/>
                                    </div>
                                    {collectYesNoQuestion &&
                                        <>
                                            <RichTextMarkdown
                                                placeholder="A great yes or no question"
                                                form={{ setFieldValue: this.handleRichTextChange }}
                                                field={{ name: 'yesNoQuestionText', value: yesNoQuestionText }}
                                                handleChange={(newValue) => this.handleRichTextChange("yesNoQuestionText", newValue)}
                                                label="Yes/No Question Text"
                                            />
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateQuestionText()}>
                                                <span className="fa fa-arrow-circle-o-up"/> Update Question Text
                                            </button>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="gdprCompliance">GDPR Compliance</label>
                                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a Yes No Question To Your Login Form</p>
                                        <input type="checkbox" checked={gdprCompliance} id="gdprCompliance" name="gdprCompliance" onClick={() => this.selectOption("gdprCompliance")}/>
                                    </div>
                                    {gdprCompliance &&
                                        <>
                                            <label htmlFor="gdprComplianceHeader" style={{marginTop: 5, marginBottom: 0}}>GDPR Pop Up Header</label>
                                            <br/>
                                            <input className="form-control" type="text" id="gdprComplianceHeader" name="gdprComplianceHeader" value={variables.gdprComplianceHeader} onChange={this.handleTextChange} placeholder="Make Sure To Read The Rules!"/>
                                            <br/>
                                            <label htmlFor="gdprComplianceMessage" style={{marginTop: 5, marginBottom: 0}}>GDPR Pop Up Message</label>
                                            <br/>
                                            <RichTextMarkdown
                                                placeholder=""
                                                form={{ setFieldValue: this.handleRichTextChange }}
                                                field={{ name: 'gdprComplianceMessage', value: gdprComplianceMessage }}
                                                handleChange={(newValue) => this.handleRichTextChange("gdprComplianceMessage", newValue)}
                                            />
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateText("gdprComplianceMessage")}><span className="fa fa-arrow-circle-o-up"/> Update GDPR Compliance Messaging</button>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="collectCustomDropdown">Collect Custom Dropdown</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add custom drop down</p>
                                        <input type="checkbox" checked={collectCustomDropdown} id="collectCustomDropdown" name="collectCustomDropdown" onClick={() => this.selectOption("collectCustomDropdown")} />
                                    </div>
                                    {collectCustomDropdown &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="customDropdownOptions">Custom Dropdown Options</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any options you want to show up in the dropdown, separate each option with the same separator. For example separating with a comma, space, or line break</p>
                                                <textarea className="form-control" id="customDropdownOptions" name="customDropdownOptions" value={this.state.customDropdownOptions} onChange={this.handleChange} />
                                                <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateCustomDropdownOptions()}><span className="fa fa-arrow-circle-o-up" /> Update Custom Dropdown Options</button>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="customDropdownPlaceholderText">Custom Dropdown Placeholder Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the text that will show up as the first option in the dropdown but will not count as a value</p>
                                                <input value={variables.customDropdownPlaceholderText} className="form-control" type="text" id="customDropdownPlaceholderText" name="customDropdownPlaceholderText" placeholder="" onChange={this.handleTextChange} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container-out container-left-rules">
                            <div className="admin-form-box">
                                <div className="form-group">
                                    <label htmlFor="collectDistance">Turn On/Off Check-In Requirement</label>
                                    <br/>
                                    <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={() => this.selectOption("collectDistance")}/>
                                    <span style={{display: collectDistance ? 'block' : 'none' }}>
                                    <div>
                                        <label htmlFor="notAcceptableLocationHeader" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleTextChange} placeholder="Hold On"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationMessage" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Message</label>
                                        <br/>
                                        <RichTextMarkdown
                                            placeholder="Please check in on the MLB app before playing!"
                                            form={{ setFieldValue: this.handleRichTextChange }}
                                            field={{ name: 'notAcceptableLocationMessage', value: notAcceptableLocationMessage }}
                                            handleChange={(newValue) => this.handleRichTextChange("notAcceptableLocationMessage", newValue)}
                                        />
                                    </div>
                                </span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                        <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                            <p style={{fontSize:'20px', fontWeight:'bold'}}>What Data Should I Gather?</p>
                            <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                                Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpLoginVariables;
