import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import {db} from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {convertTimeStampToHumanReadable, findFirstAndLastName} from 'myshared';
import DatePicker from '../utils/DatePicker';
const Papa = require('papaparse');

const isEmpty = value => value === undefined || value === null || value === "" || value === "NONE";
class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        todaysDateObject.setHours(0,0,0,0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23,59,59,999); // Sets the time to end of the day (11:59 pm)
        const splitHref = window.location.href.split("/");
        splitHref.pop();
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async componentDidMount(){
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
    }

    async loadTest() {
        const numberOfIterations = 1000;
        const batchSize = 500; // Firestore supports a maximum of 500 operations per batch
        let batch = db.batch();

        for (let i = 0; i < numberOfIterations; i++) {
            const email = `leejorgensen22+${i}@gmail.com`;
            const uid = btoa(email);
            const timeStamp = new Date().getTime();
            const user = {
                email,
                uid,
                lastSignIn: timeStamp,
                signUpTime: timeStamp
            };

            const userRef = db.collection('users').doc(uid);
            batch.set(userRef, user);

            // Commit the batch when batchSize is reached or on the last iteration
            if ((i + 1) % batchSize === 0 || i === numberOfIterations - 1) {
                await batch.commit();
                // Reinitialize the batch for the next set of writes if needed
                if (i < numberOfIterations - 1) {
                    batch = db.batch();
                }
            }
        }

        // Now, trigger the POST requests for each user as before.
        for (let t = 0; t < numberOfIterations; t++) {
            const email = `leejorgensen22+${t}@gmail.com`;
            const uid = btoa(email);
            const payload = { userId: uid };

            let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/getScratchers`;
            if (process.env.NODE_ENV === "development") {
                url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/getScratchers`;
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(`Response for user ${t}:`, data);
                })
                .catch(error => {
                    console.error(`Error for user ${t}:`, error);
                });
        }
    }

    async getGames(start = 0, end){
        if(!start){
            start = 0;
        }
        let queryString = db.collection('gamesList').orderBy('timeStamp', 'desc').where('timeStamp', '>=', start);
        if(end){
            queryString = queryString.where('timeStamp', '<=', end);
        }
        const gamesListRef = await queryString.get();
        let prizes = 0;
        let gameUsers = 0;
        const gamesList = [];
        gamesListRef.forEach(doc => {
            const gameData = doc.data();
            if(gameData.autoStatus !== 'scheduled') {
                gamesList.push(gameData);
            }
        })
        for(const i in gamesList){
            const gameToLookAt = gamesList[i];
            if(gameToLookAt.currentGame){
                const currentGamePlayersRef = await db.collection('currentGamePlayers').get();
                gameUsers += currentGamePlayersRef.size;
                prizes += gameToLookAt.totalRewardsUsed;
                gameToLookAt.users = currentGamePlayersRef.size;
            } else {
                prizes += gameToLookAt.prizesWon || gameToLookAt.totalRewardsUsed || 0;
                gameUsers += gameToLookAt.users || 0;
            }
        }
        this.setState({
            prizesWon: prizes,
            gameUsers: gameUsers,
            gamesToShowList: gamesList
        })
        // const data = await base.get('testData/testData', {
        //     context: this
        // })
        // console.log(data)
        // queryString.once('value', function (snapshot) {
        //     let prizesCount = currentGamePrizes;
        //     let gameUsersCount = currentGameUsers;
        //     let gamesArray = [];
        //     let index = 0;
        //     snapshot.forEach(function (data) {
        //         index++;
        //         prizesCount += data.val().prizes || 0;
        //         gameUsersCount += data.val().users || 0;
        //         if(snapshot.numChildren() - vm.state.itemsPerPage < index){
        //             gamesArray.unshift(data.val())
        //         }
        //     })
        //     if(currentGame){
        //         gamesArray.unshift(currentGame)
        //     }
        //     vm.setState({
        //         loading: false,
        //         games: gamesCount + snapshot.numChildren(),
        //         prizesWon: prizesCount,
        //         gameUsers: gameUsersCount,
        //         gamesToShowList: gamesArray
        //     })
        // });
    }

    async getUsers(start=0, end, returnUsers=false, asObject=false){
        let queryString = db.collection("users").orderBy('signUpTime');
        if(start){
            queryString = queryString.where('signUpTime', '>=', start);
        }
        if(end){
            queryString = queryString.where('signUpTime', '<=', end);
        }
        if(returnUsers){
             const getUsersRef = await queryString.get();
            if(asObject){
                return getUsersRef;
            } else {
                const usersArray = [];
                getUsersRef.forEach(doc => {
                    usersArray.push(doc.data())
                })
                return usersArray;
            }
        } else {
            let optInCounts = 0;
            const getUsersAndReturnSnapshot = await queryString.get();
            getUsersAndReturnSnapshot.forEach(function (data){
                const user = data.data();
                if(user.optIn || user.optInTwo || user.optInThree || user.optInFour){
                    optInCounts++;
                }
            })
            this.setState({
                loading: false,
                users: getUsersAndReturnSnapshot.size,
                optIns: optInCounts
            })
        }
    }

    async getUserData(onlyOptIns) {
        this.setState({ loading: true });
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if (typeof searchStart === "object") {
            searchStart = searchStart.getTime();
        }
        if (searchEnd && typeof searchEnd === "object") {
            searchEnd = searchEnd.getTime();
        }
        const clientName = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const dateGot = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            .toISOString()
            .split("T")[0];

        // Build CSV matrix with metadata and table rows
        const csvData = [];
        csvData.push([clientName, "Item Drop"]);
        csvData.push([]); // empty row
        csvData.push(["Date Data Downloaded", dateGot]);
        csvData.push(["From", searchStart ? convertTimeStampToHumanReadable(searchStart) : ""]);
        csvData.push(["To", searchEnd ? convertTimeStampToHumanReadable(searchEnd) : ""]);
        csvData.push([]);
        // Table header row
        const headers = [
            'Email',
            'First Name',
            'Last Name',
            'Zip Code',
            'Birthday',
            'Address',
            'Phone Number',
            'Custom Dropdown',
            'Opt-In',
            'Opt-In 2',
            'Country',
            'YesNoQuestion',
            'Signed Up'
        ];
        csvData.push(headers);

        // Data rows
        const timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        timeFrameUsers.forEach(user => {
            if (onlyOptIns && !user.optIn && !user.optInTwo && !user.optInThree && !user.optInFour) {
                return;
            }
            const { firstName, secondName } = findFirstAndLastName(user.name);
            csvData.push([
                user.email || user.uid || "",
                firstName || "",
                secondName || "",
                user.zipCode || "",
                user.birthday || "",
                user.address || "",
                user.phoneNumber || "",
                user.customDropdownInput || "",
                user.optIn || "",
                user.optInTwo || "",
                user.country || "",
                user.yesNoQuestions || "",
                user.signUpTime ? convertTimeStampToHumanReadable(user.signUpTime) : ""
            ]);
        });
        // Find index of header row (metadata rows are the first 6)
        const headerIndex = 6;
        const tableHeaders = csvData[headerIndex];
        const tableRows = csvData.slice(headerIndex + 1);
        const indicesToKeep = [];
        for (let i = 0; i < tableHeaders.length; i++) {
            if (tableRows.some(row => !isEmpty(row[i]))) {
                indicesToKeep.push(i);
            }
        }
        const filteredHeaders = indicesToKeep.map(i => tableHeaders[i]);
        const filteredRows = tableRows.map(row => indicesToKeep.map(i => row[i]));

        // Rebuild final CSV matrix (keep metadata rows unchanged)
        const finalCsvData = csvData.slice(0, headerIndex);
        finalCsvData.push(filteredHeaders);
        finalCsvData.push(...filteredRows);

        const csvContent = Papa.unparse(finalCsvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        hiddenElement.target = '_blank';
        let cleanString =
            (searchStart ? "_" + convertTimeStampToHumanReadable(searchStart) : "") +
            (searchEnd ? "_" + convertTimeStampToHumanReadable(searchEnd) : "");
        cleanString = "scratch_&_win_" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase() + ".csv";
        hiddenElement.download = cleanString;
        hiddenElement.click();
        this.setState({ loading: false });
    }

    async downloadIntenseGameData(game) {
        this.setState({ loading: true });
        const clientName = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const dateDownloaded = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            .toISOString()
            .split("T")[0];

        const usersGameDataSnap = await db
            .collection("usersGameData")
            .orderBy('requestCardsTime', 'desc')
            .where('gameId', '==', game.id)
            .get();

        // Build lookup for users and redeemed prizes
        const users = {};
        const usersSnapshot = await this.getUsers(0, null, true, true);
        usersSnapshot.forEach(doc => {
            users[doc.data().uid] = doc.data();
        });

        const prizeWonSnap = await db.collection('prizeWon').where('gameId', '==', game.id).get();
        const redeemedPrizes = {};
        prizeWonSnap.forEach(doc => {
            redeemedPrizes[doc.data().sendPrizeId] = doc.data();
        });

        let totalPrizes = 0;
        usersGameDataSnap.forEach(doc => {
            if (doc.data().prizeWonId) {
                totalPrizes++;
            }
        });

        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if (typeof searchStart === "object") {
            searchStart = searchStart.getTime();
        }
        if (searchEnd && typeof searchEnd === "object") {
            searchEnd = searchEnd.getTime();
        }

        // Build CSV matrix with metadata and table rows
        const csvData = [];
        csvData.push([clientName, "Game"]);
        csvData.push([]);
        csvData.push(["Date Data Downloaded", dateDownloaded]);
        csvData.push(["Game Name", game.gameName]);
        csvData.push(["Start Time", searchStart ? convertTimeStampToHumanReadable(searchStart) : ""]);
        csvData.push(["End Time", searchEnd ? convertTimeStampToHumanReadable(searchEnd) : ""]);
        csvData.push(["Prizes Won", totalPrizes.toString()]);
        csvData.push([]);
        // Table header row
        const headersGame = [
            'Email',
            'First Name',
            'Last Name',
            'Zip Code',
            'Birthday',
            'Address',
            'Phone Number',
            'Custom Dropdown',
            'Opt-In',
            'Opt-In 2',
            'Country',
            'YesNoQuestion',
            'Time Stamp',
            'Email Sent Time',
            'Reward',
            'Code',
            'Has been Redeemed'
        ];
        csvData.push(headersGame);

        // Table rows from usersGameData
        usersGameDataSnap.forEach(doc => {
            const userInfo = doc.data();
            const moreUserData = users[userInfo.uid] || {};
            const { firstName, secondName } = findFirstAndLastName(moreUserData.name);
            csvData.push([
                userInfo.email || userInfo.uid || "",
                firstName || "",
                secondName || "",
                moreUserData.zipCode || "",
                moreUserData.birthday || userInfo.birthday || "",
                moreUserData.address || userInfo.address || "",
                moreUserData.phoneNumber || userInfo.phoneNumber || "",
                moreUserData.customDropdownInput || "",
                moreUserData.optIn || "",
                moreUserData.optInTwo || "",
                moreUserData.country || "",
                moreUserData.yesNoQuestions || "",
                userInfo.requestCardsTime ? convertTimeStampToHumanReadable(userInfo.requestCardsTime) : "",
                userInfo.emailTimeSent
                    ? convertTimeStampToHumanReadable(userInfo.emailTimeSent.seconds || userInfo.emailTimeSent)
                    : "",
                userInfo.rewardName || "",
                userInfo.code || "",
                (userInfo.isRedeemed || (redeemedPrizes[userInfo.prizeWonId] && redeemedPrizes[userInfo.prizeWonId].redeemed)) ? "Yes" : ""
            ]);
        });

        // Remove empty columns from the game table.
        const headerIdxGame = csvData.findIndex(row => row[0] === 'Email');
        const gameHeaders = csvData[headerIdxGame];
        const gameRows = csvData.slice(headerIdxGame + 1);
        const indicesToKeepGame = [];
        for (let i = 0; i < gameHeaders.length; i++) {
            if (gameRows.some(row => !isEmpty(row[i]))) {
                indicesToKeepGame.push(i);
            }
        }
        const filteredGameHeaders = indicesToKeepGame.map(i => gameHeaders[i]);
        const filteredGameRows = gameRows.map(row => indicesToKeepGame.map(i => row[i]));

        const finalCsvGameData = csvData.slice(0, headerIdxGame);
        finalCsvGameData.push(filteredGameHeaders);
        finalCsvGameData.push(...filteredGameRows);

        const csvContent = Papa.unparse(finalCsvGameData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "gamedata_" + cleanString + ".csv";
        hiddenElement.click();
        this.setState({ loading: false });
    }

    async downloadAllGamesData() {
        this.setState({ loading: true });
        const { searchStart, searchEnd } = this.state;
        const start = searchStart ? searchStart.getTime() : 0;
        const end = searchEnd ? searchEnd.getTime() : new Date().getTime();

        // 1) Get all games within [start, end]
        let gamesQuery = db
            .collection('gamesList')
            .orderBy('timeStamp', 'desc')
            .where('timeStamp', '>=', start);
        if (end) {
            gamesQuery = gamesQuery.where('timeStamp', '<=', end);
        }
        const gamesSnapshot = await gamesQuery.get();
        const gamesList = [];
        gamesSnapshot.forEach(doc => {
            gamesList.push(doc.data());
        });

        // 2) Fetch all users for quick lookup
        const usersSnapshot = await this.getUsers(0, null, true, true);
        const usersData = {};
        usersSnapshot.forEach(doc => {
            usersData[doc.data().uid] = doc.data();
        });

        // 3) Build redeemed prizes lookup
        const gameIds = gamesList.map(g => g.id);
        const chunkSize = 10;
        const redeemedPrizes = {};
        for (let i = 0; i < gameIds.length; i += chunkSize) {
            const chunk = gameIds.slice(i, i + chunkSize);
            if (!chunk.length) continue;
            const prizeSnapshot = await db
                .collection('prizeWon')
                .where('gameId', 'in', chunk)
                .get();
            prizeSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.prizeWonId) {
                    redeemedPrizes[data.prizeWonId] = data;
                }
            });
        }

        const dateDownloaded = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
            .toISOString()
            .split('T')[0];

        // Build CSV matrix with metadata and fixed table headers
        const csvData = [];
        csvData.push([process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID]);
        csvData.push([]);
        csvData.push(["Date Data Downloaded", dateDownloaded]);
        csvData.push(["From", start ? convertTimeStampToHumanReadable(start) : ""]);
        csvData.push(["To", end ? convertTimeStampToHumanReadable(end) : ""]);
        csvData.push([]);
        const headersAll = [
            'Email',
            'First Name',
            'Last Name',
            'Zip Code',
            'Birthday',
            'Address',
            'Phone Number',
            'Opt-In',
            'Opt-In 2',
            'Country',
            'YesNoQuestion',
            'Game Name',
            'Time Stamp',
            'Email Sent Time',
            'Reward',
            'Code',
            'Has Been Redeemed'
        ];
        csvData.push(headersAll);

        // For each game, get the usersGameData and build rows
        for (const game of gamesList) {
            const userGameDataSnap = await db
                .collection('usersGameData')
                .where('gameId', '==', game.id)
                .orderBy('requestCardsTime', 'desc')
                .get();
            userGameDataSnap.forEach(doc => {
                const userInfo = doc.data();
                const moreUserData = usersData[userInfo.uid] || {};
                const { firstName, secondName } = findFirstAndLastName(moreUserData.name);
                csvData.push([
                    userInfo.email || userInfo.uid || '',
                    firstName || '',
                    secondName || '',
                    moreUserData.zipCode || '',
                    moreUserData.birthday || userInfo.birthday || '',
                    moreUserData.address || userInfo.address || '',
                    moreUserData.phoneNumber || userInfo.phoneNumber || '',
                    moreUserData.optIn || '',
                    moreUserData.optInTwo || '',
                    moreUserData.country || '',
                    moreUserData.yesNoQuestions || '',
                    game.gameName || '',
                    userInfo.requestCardsTime ? convertTimeStampToHumanReadable(userInfo.requestCardsTime) : '',
                    userInfo.emailTimeSent
                        ? convertTimeStampToHumanReadable(userInfo.emailTimeSent.seconds || userInfo.emailTimeSent)
                        : '',
                    userInfo.rewardName || '',
                    userInfo.code || '',
                    (userInfo.isRedeemed ||
                        (userInfo.prizeWonId &&
                            redeemedPrizes[userInfo.prizeWonId] &&
                            redeemedPrizes[userInfo.prizeWonId].redeemed))
                        ? 'Yes'
                        : ''
                ]);
            });
        }

        // (Optional) Remove empty columns from the table portion if needed.
        // Here we assume the header row is the one we just pushed.
        const headerIdxAll = csvData.findIndex(row => row[0] === 'Email');
        const allHeaders = csvData[headerIdxAll];
        const allRows = csvData.slice(headerIdxAll + 1);
        const indicesToKeepAll = [];
        for (let i = 0; i < allHeaders.length; i++) {
            if (allRows.some(row => !isEmpty(row[i]))) {
                indicesToKeepAll.push(i);
            }
        }
        const filteredAllHeaders = indicesToKeepAll.map(i => allHeaders[i]);
        const filteredAllRows = allRows.map(row => indicesToKeepAll.map(i => row[i]));
        const finalCsvAllData = csvData.slice(0, headerIdxAll);
        finalCsvAllData.push(filteredAllHeaders);
        finalCsvAllData.push(...filteredAllRows);

        const csvContent = Papa.unparse(finalCsvAllData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        hiddenElement.target = '_blank';
        hiddenElement.download = `all_games_data_${dateDownloaded}.csv`;
        hiddenElement.click();
        this.setState({ loading: false });
    }

    searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, ()=>{

        })
        this.getGames(searchStart,searchEnd)
        this.getUsers(searchStart,searchEnd)
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').limitToLast(newAmountToShow).startAt(searchStart);
        if (searchEnd) {
            queryString = queryString.endAt(searchEnd)
        }
        queryString.once('value', function (snapshot) {
            let gamesArray = [];
            snapshot.forEach(function (data) {
                gamesArray.unshift(data.val())
            })
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        })
    }

    render() {
        const scoreboardOnly = this.props.scoreboardOnly;
        const totalUsers = this.state.users || 0;
        const optIns = this.state.optIns || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        const isSqwadEmployee = this.props.isSqwadEmployee || false;
        const isDev =  process.env.REACT_APP_FIREBASE_PROJECT_ID === 'itemdropgamedev';
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span></p>
                    </div>
                    <div className="col-md-1">
                        <p style={{color: "black"}}>
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                    {/*<div className="col-md-1">*/}
                    {/*    <button onClick={() => this.loadTest()}>Load Test</button>*/}
                    {/*</div>*/}
                </div>
                <form className="form-horizontal" style={{marginLeft: 20, marginBottom:10}}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchStart}
                                onChange={date => {
                                    if(date) date.setHours(0,0,0,0);
                                    this.setState({searchStart: date});
                                }}
                            />
                        </div>

                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchEnd}
                                onChange={date => {
                                    if(date) date.setHours(23,59,59,999);
                                    this.setState({searchEnd: date});
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                        {!scoreboardOnly && (isSqwadEmployee || isDev) &&
                            <div className="col-auto">
                                <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.downloadAllGamesData()}>Download All Games</button>
                            </div>
                        }
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{totalUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Accounts Created</span>
                                    </div>
                                    {/*<div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>*/}
                                    {/*    <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{optIns}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Opt Ins</span>
                                    </div>
                                    <div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>
                                        <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData(true)}/>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{gameUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Game Users</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{prizesWon}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Prizes Won</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div style={{margin:20}}>
                    {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                    <>
                        {
                            this.state.gamesToShowList.map(function (item, index) {
                                return (
                                    <div key={index} className="card card-styles text-xs-center" style={{marginBottom: 10}}>
                                        <div className="card-body" style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px"}}>
                                            <div className="row" style={{alignItems: 'center'}}>
                                                <div className="col-md-2">
                                                    <span style={{color: 'black', fontSize: 14}}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                </div>
                                                <div className="col-md-3" style={{fontSize: 30}}>
                                                    {item.gameName} {item.autoStatus === "scheduled" && "- Scheduled"}
                                                </div>
                                                <div className="col-md-3" style={{textAlign: 'right'}}>
                                                    <span style={{fontSize: 25}}>{item.users || item.gameUsers || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15, marginRight: 15}}>Played</span>
                                                    <span style={{fontSize: 25}}>{item.prizes || item.prizesWon || item.totalRewardsUsed || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15}}>Prizes Won</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row" style={{fontSize: 20, justifyContent: 'center'}}>
                                                        {item.prizesWon !== null && !item.currentGame && (item.users > 0 || item.gameUsers > 0) &&
                                                            <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                <span className="fa fa-arrow-circle-down"/> Download Game Data
                                                            </button>
                                                        }
                                                        {item.currentGame &&
                                                            <button onClick={() => window.location.href='/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                Go To Current Game
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }, this)
                        }
                        {this.state.games > this.state.gamesToShowList.length &&
                            <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                                More
                            </button>
                        }
                    </>
                    }
                </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
