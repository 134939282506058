import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {base, db} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import DatePicker from '../utils/DatePicker';
import InfiniteScroll from 'react-infinite-scroller';
import {
  convertTimeStampToHumanReadable,
  findFirstAndLastName,
  deleteCollection,
  deleteCollectionWithQuery,
  sortPlayersForAdmin
} from 'myshared';
import {detectHowManyCodesEntered} from "../utils/HelpfulFunction";
import Papa from 'papaparse'; // make sure Papa is imported at the top

export class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ticketList: [],
          rewardsAdded: [],
          attachedTickets: [],
          currentGameRewards: [],
          otherNumber: 0,
          gotRewards: false,
          csvRewards: [],
          scratcherGame: null,
          gameStartTime: new Date(),
          gameEndTime: new Date(),
          cardsPerPack: '0',
          modal: false,
          players: 0,
          rewardToAdd: '',
          rewardAmount: 0,
          prizesWon: 0,
          codes: false,
          allowMoreScratchersAfterTime: false,
          convertToQrCode: false,
          codesArray: '',
          pinsArray: '',
          autoGame: false,
          loading: true,
          setIntervalId: null,
          wizardLocation: 'first',
          showStats: true,
          losingTickets: null,
          activePage: 1,
          totalPlayers: 0,
          gameName: "",
          page: 1,
          responseSize: 100,
          futureGamesList: [],
          showWinning: true,
          firstLoad: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
        this.toggleAutoGame = this.toggleAutoGame.bind(this);
    }

    async componentDidMount() {
      this.tenantVariablesRef = base.bindDoc(`tenantVariables/default`, {
        context: this,
        state: 'tenantVariables',
      });
      this.currentGameRef = base.listenToCollection(`gamesList`, {
        context: this,
        query: (ref) => ref.where('currentGame', '==', true).orderBy('timeStamp', "desc").limit(1),
        then(data){
          let currentGame = {};
          if(data.length > 0){
            currentGame = data[0];
            this.getRedemptions(currentGame.id);
            this.getRewardsInGame(currentGame.id);
          }
          let setStateObject = {currentGame: currentGame};
          if(this.state.firstLoad){
            setStateObject['loading'] = false;
            setStateObject['firstLoad'] = false;
          }
          this.setState(setStateObject)
        },
        onFailure(err){
          console.log(err)
          this.setState({
            loading: false,
          })
        }
      })
      this.ticketListRef = base.bindCollection(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
      // this.prizeWonRef = base.listenToCollection('prizeWon', {
      //   context: this,
      //   query: (ref) => ref.where('gameId', '==', true).orderBy('timeStamp', "desc").limit(1),
      //   then(data) {
      //     this.setState({
      //       prizesWon: data.length
      //     })
      //   },
      //   onFailure(err) {
      //     //handle error
      //   }
      // });
      this.currentGamePlayersRef = base.listenToCollection('currentGamePlayers', {
        context: this,
        then(data) {
          this.setState({
            players: data.length
          })
        },
        onFailure(err) {
          //handle error
        }
      });
      this.futureGamesListRef = db.collection('gamesList').where("autoStatus", "==", "scheduled").orderBy('startTime').onSnapshot((querySnapshot) => {
        const arrayOfFutureGames = [];
        querySnapshot.forEach((doc) => {
          const game = doc.data();
          if(!game.currentGame){
            arrayOfFutureGames.push(doc.data());
          }
        });
        this.setState({
          futureGamesList: arrayOfFutureGames
        })
      })
    }

    downloadUsers() {
      this.setState({ loading: true });
      const vm = this;
      // Build CSV as an array-of-arrays
      const csvData = [];
      const headers = [
        'Email',
        'First Name',
        'Last Name',
        'Zip Code',
        'Birthday',
        'Address',
        'Phone Number',
        'Custom Dropdown',
        'Opt-In',
        'Opt-In 2',
        'Country',
        'YesNoQuestions',
        'Time Stamp',
        'Reward',
        'Code',
        'Has been Redeemed'
      ];
      csvData.push(headers);

      Promise.all([db.collection("users").get()]).then(async function (snapshots) {
        let users = {};
        snapshots[0].forEach(doc => {
          users[doc.data().uid] = doc.data();
        });
        const currentUsersState = await sortPlayersForAdmin(0, null, vm.state.currentGame, db);
        currentUsersState[0].forEach(row => {
          const user = users[row.uid];
          const { firstName, secondName } = findFirstAndLastName(user.name);
          const rowData = [
            row.email || row.uid || "",
            firstName || "",
            secondName || "",
            user.zipCode || "",
            user.birthday || "",
            user.address || "",
            user.phoneNumber || "",
            user.customDropdownInput || "",
            user.optIn || "",
            user.optInTwo || "",
            user.country || "",
            user.yesNoQuestions || "",
            row.requestCardsTime ? convertTimeStampToHumanReadable(row.requestCardsTime.seconds || row.requestCardsTime) : "",
            row.rewardName || "",
            row.code || "",
            row.isRedeemed ? "Yes" : ""
          ];
          csvData.push(rowData);
        });

        // Remove columns that are completely empty
        const isEmpty = value => value === undefined || value === null || value === "" || value === "NONE";
        const tableHeaders = csvData[0];
        const tableRows = csvData.slice(1);
        const indicesToKeep = [];
        for (let i = 0; i < tableHeaders.length; i++) {
          if (tableRows.some(row => !isEmpty(row[i]))) {
            indicesToKeep.push(i);
          }
        }
        const filteredHeaders = indicesToKeep.map(i => tableHeaders[i]);
        const filteredRows = tableRows.map(row => indicesToKeep.map(i => row[i]));
        const finalCsvData = [filteredHeaders, ...filteredRows];

        // Generate CSV content using Papa.unparse and trigger download
        const csvContent = Papa.unparse(finalCsvData);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        hiddenElement.target = '_blank';
        const dateGot = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
            .toISOString()
            .split("T")[0] + ".csv";
        hiddenElement.download = "current_game_users_" + dateGot;
        hiddenElement.click();
        vm.setState({ loading: false });
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameRef);
      base.removeBinding(this.ticketListRef);
      base.removeBinding(this.tenantVariablesRef);
      // base.removeBinding(this.prizeWonRef);
      base.removeBinding(this.currentGamePlayersRef);
      if (this.futureGamesListRef) {
        this.futureGamesListRef();
      }
      if(this.redeemedPrizeRef){
        base.removeBinding(this.redeemedPrizeRef);
      }
      if(this.currentGameRewardsListRef){
        base.removeBinding(this.currentGameRewardsListRef);
      }
    }

    async createGame(){
      const createGameObject = {};
      const gameId = db.collection("gamesList").doc().id;
      const gameStartTime = this.state.gameStartTime;
      const gameEndTime = this.state.gameEndTime;
      const gameName = this.state.gameName.trim();
      if(!gameName){
        swal({
          title: 'Hold on!',
          text: 'This game needs a name',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return
      }
      var cardsPerPack = parseInt(this.state.cardsPerPack, 10);
      var rewards = this.state.rewardsAdded;
      const sendFollowUpEmail = this.state.sendFollowUpEmail || false;
      var losingTicketsToCreate = this.state.losingTickets;
      var otherNumber = this.state.otherNumber;
      const addPlayerLimits = this.state.addPlayerLimits || false;
      const playerLimit = parseInt(this.state.playerLimit || 0);
      var rewardsArray = {};
      const localISOTime = new Date().getTime();
      const startTimeMill = gameStartTime.getTime();
      const endTimeMill = gameEndTime.getTime();
      let allowMultiplePlays = this.state.allowMultiplePlays;
      let numberOfPlaysAllowed = this.state.numberOfPlaysAllowed;
      let numberOfHoursBetweenPlays = this.state.numberOfHoursBetweenPlays;
      let losingRewardToAdd = this.state.losingRewardToAdd || "";
      let hasLosingReward = false;
      let losingReward = false;
      if(losingRewardToAdd){
        const ticketList = this.state.ticketList || [];
        for(const ticketListIndex in ticketList){
          const ticket = ticketList[ticketListIndex] || {};
          if(ticket.id === losingRewardToAdd){
            hasLosingReward = true;
            losingReward = ticket;
          }
        }
      }
      if(addPlayerLimits && (!playerLimit || playerLimit < 0)){
        swal({
          title: 'Hold on!',
          text: 'If player limit is toggled on it must be a positive number',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return
      }
      if(this.state.autoGame){
        if(startTimeMill === endTimeMill){
          swal({
            title: 'Hold on!',
            text: 'You have scheduled a game but the start and end times are equal! Go back to the first tab to update your times',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(startTimeMill > endTimeMill){
          swal({
            title: 'Hold on!',
            text: 'Game Start time cant be after game end time!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(startTimeMill < localISOTime) {
          swal({
            title: 'Hold on!',
            text: 'Game Start time before now!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        } else if(!startTimeMill || !endTimeMill){
          swal({
            title: 'Hold on!',
            text: 'You have scheduled a game but no start time or end time',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        }
        createGameObject.startTime = new Date(gameStartTime).toISOString();
        createGameObject.endTime = new Date(gameEndTime).toISOString();
        if(gameStartTime){
          createGameObject.autoStatus = "scheduled";
        } else if(!gameStartTime && gameEndTime){
          createGameObject.autoStatus = "active";
        }
      } else {
        createGameObject.startTime = null;
        createGameObject.endTime = null;
      }
      if(cardsPerPack < 1 || !cardsPerPack){
        swal({
          title: "You haven't added any cards",
          text: 'Cards per pack needs to be greater than 0.  Go back to the first tab to add cards',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      if(cardsPerPack > 5){
        swal({
          title: "Sorry!",
          text: 'At this time we do not allow games with more than 5 cards per pack. Reach out to Sqwad if you have any questions.',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      if(rewards.length === 0){
        swal({
          title: "You haven't added any prizes!",
          text: 'Go to the second tab and add at least 1 prize',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      try {
        otherNumber = parseInt(otherNumber)
      } catch (e) {
        swal({
          title: "Difficulty Error",
          text: 'Please select a difficulty or make sure a number above zero is entered as a difficulty',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return
      }
      if(!losingTicketsToCreate && otherNumber == 0){
        swal({
          title: "No difficulty selected",
          text: 'Please select a difficulty before creating a game.',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (otherNumber < 0) {
        swal({
          title: "Difficulty Error",
          text: 'Make sure a number above zero is entered as a difficulty',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      let amountOfRewards = 0;
      let hasRewardWithAutoRedemptions = false;
      for(const reward in rewards){
        const rewardObject = {};
        rewardObject.id = rewards[reward].id;
        rewardObject.noOfAvailableTickets = parseInt(rewards[reward].amount, 10);
        rewardObject.usedTickets = 0;
        amountOfRewards = amountOfRewards + parseInt(rewards[reward].amount, 10)
        rewardObject.codes = rewards[reward].codesArray || [];
        rewardObject.pins = rewards[reward].pinsArray || [];
        rewardObject.hasCodes = rewards[reward].codes;
        rewardObject.convertToQrCode = rewards[reward].convertToQrCode;
        rewardObject.revealImage = rewards[reward].reward.revealImage;
        rewardObject.emailImage = rewards[reward].reward.emailImage;
        rewardObject.rewardName = rewards[reward].reward.rewardName;
        rewardObject.rewardDisplayName = rewards[reward].reward.rewardDisplayName || "";
        rewardObject.vendorName = rewards[reward].reward.vendorName || "";
        rewardObject.expiresAtHours = rewards[reward].reward.expiresAtHours || "";
        rewardObject.expiresAtDateTime = rewards[reward].reward.expiresAtDateTime || "";
        rewardObject.templateText = rewards[reward].reward.templateText || "";
        rewardObject.description = rewards[reward].reward.description || "";
        rewardObject.rewardLinkButtonColor = rewards[reward].reward.rewardLinkButtonColor || "";
        rewardObject.rewardLinkButtonText = rewards[reward].reward.rewardLinkButtonText || "";
        rewardObject.rewardLinkButtonTextColor = rewards[reward].reward.rewardLinkButtonTextColor || "";
        rewardObject.showButton = rewards[reward].reward.showButton || false;
        rewardObject.rewardLink = rewards[reward].reward.rewardLink;
        rewardObject.subjectLine = rewards[reward].reward.subjectLine;
        rewardObject.isRedeemable = rewards[reward].reward.isRedeemable || false;
        if(rewardObject.isRedeemable){
          hasRewardWithAutoRedemptions = true;
        }
        rewardObject.isWinning = true;
        rewardsArray[rewards[reward].id] = rewardObject;
      }
      if(hasLosingReward){
        createGameObject.hasLosingReward = true;
        createGameObject.losingReward = losingReward;
      }
      // createGameObject.rewards = rewardsArray;
      createGameObject.rewardsPerPack = cardsPerPack;
      createGameObject.totalRewards = amountOfRewards;
      createGameObject.totalRewardsUsed = 0;
      createGameObject.addPlayerLimits = addPlayerLimits;
      createGameObject.playerLimit = playerLimit;
      createGameObject.gameName = gameName;
      createGameObject.sendFollowUpEmail = sendFollowUpEmail;
      createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
      if(allowMultiplePlays){
        createGameObject.allowMultiplePlays = allowMultiplePlays;
      }
      if(numberOfPlaysAllowed && allowMultiplePlays){
        createGameObject.numberOfPlaysAllowed = parseInt(numberOfPlaysAllowed);
        createGameObject.numberOfHoursBetweenPlays = numberOfHoursBetweenPlays ? parseFloat(numberOfHoursBetweenPlays) : 0;
      }
      let percentChanceToWin;
      if (otherNumber){
        percentChanceToWin = otherNumber;
      } else {
        if(losingTicketsToCreate === "easy"){
          percentChanceToWin = 3;
        } else if(losingTicketsToCreate === "medium") {
          percentChanceToWin = 30;
        } else {
          percentChanceToWin = 300;
        }
      }

      createGameObject.difficulty = percentChanceToWin;

      createGameObject.currentGame = createGameObject.autoStatus !== "scheduled";
      createGameObject.active = false;
      const currentGame = this.state.currentGame || {};
      createGameObject.timeStamp = Date.now();
      createGameObject.id = gameId;
      createGameObject.isMlbGame = process.env.REACT_APP_IS_MLB_TEAM === "true";
      await this.saveDeleteCurrentGameDataReplace(currentGame, createGameObject, rewardsArray);
    }

    async saveDeleteCurrentGameDataReplace(currentGame, createGameObject, rewardsArray){
      this.setState({
        loading: true
      })
      if(currentGame.id && createGameObject.currentGame){
        currentGame['active'] = false;
        currentGame['currentGame'] = false;
        if(currentGame.autoStatus){
          currentGame.autoStatus = 'completed';
        }
        const currentGamePlayersRef = await db.collection('currentGamePlayers').get();
        currentGame['users'] = currentGamePlayersRef.size;
        const prizesWonRef = await db.collection('prizeWon').where("gameId", "==", currentGame.id).get();
        currentGame['prizesWon'] = prizesWonRef.size;
        //prizes used saved under totalRewardsUsed
        const userAnswers = await sortPlayersForAdmin(0, null, currentGame, db);
        const usersAnswersObject = {};
        let batch = db.batch();
        let count = 0;
        let batchUpdatesArray = [];
        for(const i in userAnswers[0]){
          const userAnswer = userAnswers[0][i] || {};
          if(userAnswer && userAnswer.uid){
            count++;
            let collectionPushId = db.collection('usersGameData').doc().id;
            const userGameDataRef = db.collection("usersGameData").doc(collectionPushId);
            userAnswer['id'] = collectionPushId;
            userAnswer['gameId'] = currentGame.id;
            batch.set(userGameDataRef, userAnswer);
            if(count >= 500 || userAnswers[0].length - 1 === parseInt(i)){
              batchUpdatesArray.push(batch.commit());
              batch = db.batch();
              count = 0;
            }
            usersAnswersObject[collectionPushId] = userAnswer;
          }
        }
        await Promise.all(batchUpdatesArray)
        await base.updateDoc(`gamesList/${currentGame.id}`, currentGame);
        const otherActiveGamesListRef = await db.collection('gamesList').where('active','==',true).get();
        const gamesListThatShouldBeInActive = [];
        otherActiveGamesListRef.forEach(function (snapshot){
          gamesListThatShouldBeInActive.push(base.updateDoc(`gamesList/${snapshot.id}`, {active: false, currentGame: false}))
        })
        await Promise.all(gamesListThatShouldBeInActive);
        await deleteCollection(db, `currentGamePlayers`, 100)
        // await deleteCollection(db, `prizeWon`, 100)
        await this.setState({
          currentGameRewards: [],
          currentUserAnswersState: []
        })
      }
      await base.addToCollection('gamesList', createGameObject, createGameObject.id);
      for (const [i, rewardToUpload] of Object.entries(rewardsArray)) {
        if (rewardToUpload.templateText === undefined) {
          rewardToUpload.templateText = "";
        }

        try {
          const codes = rewardToUpload.codes || [];
          const pins = rewardToUpload.pins || [];
          delete rewardToUpload['codes'];
          delete rewardToUpload['pins'];

          await base.addToCollection(`gamesList/${createGameObject.id}/rewards`, rewardToUpload, rewardToUpload.id);

          if (codes.length > 0) {
            const rewardsData = codes.map((code, index) => {
              let obj = {code: code, used: false};
              if(pins[index]) {
                obj['pin'] = pins[index];
              }
              return obj;
            });
            let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/batchWrite`;
            if(process.env.NODE_ENV === "development"){
              url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/batchWrite`;
            }

            const bearerToken = await this.props.currentUser.getIdToken();
            const bearerTokenString = "Bearer " + bearerToken;

            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
              },
              body: JSON.stringify({
                data: rewardsData,
                collection: `gamesList/${createGameObject.id}/rewards/${rewardToUpload.id}/codes`
              }),
            });

            if (response.ok) {
              console.log('Data received. Writing in progress.');
            } else {
              console.log('Error publishing message: ', response.statusText);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      await this.setState({
        modal: false,
        loading: false
      })
    }

    async getRedemptions(gameId) {
      if (!gameId) return;
      this.redeemedPrizeRef = base.listenToCollection(`prizeWon`, {
        context: this,
        query: (ref) => ref.where('gameId', '==', gameId),
        then(response){
          let redemptions = 0;
          response.forEach(function (data) {
            if(data.redeemed) redemptions++;
          })
          this.setState({
            redemptions: redemptions,
            prizesWon: response.length
          })
        },
        onFailure(err){
          console.log(err)
        }
      })
    }

    async getRewardsInGame(gameId){
      if (!gameId) return;
      this.currentGameRewardsListRef = base.listenToCollection(`gamesList/${gameId}/rewards`, {
        context: this,
        asArray: true,
        then(data){
          this.setState({
            currentGameRewardsList: data
          })
        },
        onFailure(err){
          console.log(err)
        }
      })
    }

    toggleAutoGame(){
      this.setState({
        autoGame: !this.state.autoGame
      })
    }

    startGame(){
      let currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = true
        currentGame.timeStamp = Date.now();
        this.setState({loading: true})
        base.updateDoc(`gamesList/${currentGame.id}`, currentGame)
            .then(() => {
              this.setState({loading: false})
            }).catch(err => {
              this.setState({loading: false})
        });
      } else {
        swal({
          title: 'Hold on!',
          text: 'No game to start. Create a game first',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
    }

    stopGame(){
      var currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = false;
        this.setState({loading: true})
        base.updateDoc(`gamesList/${currentGame.id}`, currentGame)
            .then(() => {
              this.setState({loading: false})
            }).catch(err => {
          this.setState({loading: false})
        });
      }
    }

    reset(){
      const currentGame = this.state.currentGame;
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will reset peoples picks!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        }).then(async (result)=>{
          if(result.value){
            this.setState({
              loading: true
            })
            const rewardsCollection = await db.collection(`gamesList/${currentGame.id}/rewards`).get();
            const setUsedTicketsToZeroArray = [];
            rewardsCollection.forEach(function(data) {
              const rewardId = data.data().id;
              setUsedTicketsToZeroArray.push(db.collection(`gamesList/${currentGame.id}/rewards`).doc(rewardId).update({usedTickets: 0}))
            })
            await Promise.all(setUsedTicketsToZeroArray);
            await db.collection(`gamesList`).doc(currentGame.id).update({totalRewardsUsed: 0, active: false, playerCount: 0})
            await deleteCollection(db, `currentGamePlayers`, 100);
            await this.resetAllCodes();
            await deleteCollectionWithQuery(db, db.collection(`prizeWon`).where("gameId", "==", currentGame.id), 100)
            this.setState({
              loading: false,
              currentUserAnswersState: [],
              currentGameRewards: []
            })
            swal({
              title: 'Reset Successful',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    async resetAllCodes() {
      const batchSize = 50; // Firestore batch limit
      const gamesListRef = db.doc(`gamesList/${this.state.currentGame.id}`);
      const rewardsRef = gamesListRef.collection('rewards');

      const rewardsSnapshot = await rewardsRef.get();
      for (let rewardDoc of rewardsSnapshot.docs) {
        const rewardId = rewardDoc.id;
        const codesRef = rewardsRef.doc(rewardId).collection('codes').where('used', '==', true);

        let query = codesRef.limit(batchSize);

        await new Promise((resolve, reject) => {
          const updateBatch = (query) => {
            query.get().then((snapshot) => {
              // When there are no documents left, we are done
              if (snapshot.size === 0) {
                return resolve();
              }

              // Update documents in a batch
              let batch = db.batch();
              snapshot.docs.forEach((doc) => {
                batch.update(doc.ref, {used: false});
              });

              // Continue with next batch
              return batch.commit().then(() => {
                const lastDoc = snapshot.docs[snapshot.docs.length - 1];
                const nextQuery = codesRef.startAfter(lastDoc).limit(batchSize);
                return updateBatch(nextQuery);
              });
            }).catch(reject);
          };

          // Start the first batch
          updateBatch(query);
        });
      }
    };

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first"
      });
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
      if(buttonClicked === "showStats"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:true,
          showPrizes:false,
          showGames:false
        })
      }else if(buttonClicked === "showPrizes"){
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        this.setState({
          loading: true
        })
        const organizeUserAnswers = await sortPlayersForAdmin(0, null, this.state.currentGame, db);
        this.setState({
          showStats:false,
          showPrizes:true,
          showGames:false,
          loading: false,
          currentGameRewards: organizeUserAnswers[0]
        })
      } else if(buttonClicked === "showGames"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
          showStats:false,
          showPrizes:false,
          showGames:true,
        })
      }
    }

    removeFromToAddArray(index, name){
      let removeArray = this.state[name];
      removeArray.splice(index, 1)
      this.setState({
        [name]: removeArray
      })
    }

    async handleLoadMore(page){
      const anotherResponse = await sortPlayersForAdmin(this.state.responseSize*page, document.getElementById('searchQuery').value, this.state.currentGame, db);
      const pageNumberAccurate = this.state.responseSize*page / this.state.responseSize;
      this.setState({
        currentGameRewards: anotherResponse[0],
        page: pageNumberAccurate
      })
    }

    saveReward (evt) {
      this.setState({ rewardToAdd: evt.target.value });
    }

    addRewardToLocalArray() {
      var rewardAmount = this.state.rewardAmount;
      var rewardId = this.state.rewardToAdd;
      var codes = this.state.codes;
      let convertToQrCode = this.state.convertToQrCode;
      let codesArray = this.state.codesArray;
      let pinsArray = this.state.pinsArray;
      if(!codes){
        codesArray = "";
        pinsArray = "";
        convertToQrCode = false;
      }
      codesArray = detectHowManyCodesEntered(codesArray);
      pinsArray = detectHowManyCodesEntered(pinsArray);
      if(codesArray.length === 0 && codes){
        swal({
          title: 'Hold on!',
          text: 'Must enter codes or uncheck codes box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (codes){
        if(codesArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of codes must equal number of prizes created. In this case: " +  codesArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        } else if(pinsArray.length !== 0 && pinsArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Pins not equal!',
            text: "# of pins must equal number of prizes created. In this case: " +  pinsArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
        swal({
          title: 'Missing field!',
          text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }

      for(var ticket in this.state.ticketList){
        if(this.state.ticketList[ticket].id === rewardId){
          var reward = this.state.ticketList[ticket]
          var rewardToAddObject = {};
          rewardToAddObject.reward = reward;
          rewardToAddObject.id = rewardId;
          rewardToAddObject.amount = rewardAmount;
          rewardToAddObject.codes = codes;
          rewardToAddObject.convertToQrCode = convertToQrCode;
          rewardToAddObject.codesArray = codesArray;
          rewardToAddObject.pinsArray = pinsArray;
        }
      }
      var array = this.state.rewardsAdded;
      var rewardsAddedIds = []
      for(var prize in array){
        rewardsAddedIds.push(array[prize].id)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          rewardsAdded: array,
          convertToQrCode: false,
          codes: false,
          codesArray: "",
          pinsArray: ""
        })
      } else {
        swal({
          title: 'Prize already added!',
          text: "You have already added this prize",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
    }

    openCreateGameModal(){
    if(this.state.scratcherGame){
      if(this.state.scratcherGame.startTime){
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = new Date(Date.now()).getTime();
        var gameStartTime = this.state.scratcherGame.startTime;
        var reformatedStartDate = gameStartTime.split("T")[0].split("-")[1] + "/" + gameStartTime.split("T")[0].split("-")[2] + "/" + gameStartTime.split("T")[0].split("-")[0]
        gameStartTime = reformatedStartDate + " " + gameStartTime.split("T")[1]
        if(localISOTime < new Date(gameStartTime).getTime() - tzoffset){
          swal({
            title: 'Hold on!',
            text: 'The start time of the current game is in the future.  Are you sure you want to replace it?  All data associated with it will be deleted and unrecoverable.  Is that ok?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
          }).then((result)=>{
            if(result.value){
              this.setState({loading: true});
            }
          })
        } else {
          this.setState({
            modal: true,
          }, () => {
          });
        }
      } else {
        this.setState({
          modal: true,
        }, () => {
        });
      }
    } else {
      this.setState({
        modal: true,
      }, () => {
      });
    }
  }

    calculateOdds(difficulty){
    if(difficulty === "easy"){
      document.getElementById('easyOdds').classList.add('btn-primary');
      document.getElementById('mediumOdds').classList.remove('btn-primary');
      document.getElementById('hardOdds').classList.remove('btn-primary');
    } else if(difficulty === "medium"){
      document.getElementById('easyOdds').classList.remove('btn-primary');
      document.getElementById('mediumOdds').classList.add('btn-primary');
      document.getElementById('hardOdds').classList.remove('btn-primary');
    } else if(difficulty === "hard") {
      document.getElementById('easyOdds').classList.remove('btn-primary');
      document.getElementById('mediumOdds').classList.remove('btn-primary');
      document.getElementById('hardOdds').classList.add('btn-primary');
    } else {
      difficulty = null;
      document.getElementById('easyOdds').classList.remove('btn-primary');
      document.getElementById('mediumOdds').classList.remove('btn-primary');
      document.getElementById('hardOdds').classList.remove('btn-primary');
    }

    if(difficulty){
      this.setState({
        losingTickets: difficulty,
        otherNumber: 0
      })
    } else {
      this.setState({
        losingTickets: difficulty,
      })
    }
  }

    async setRewardRedeemed(reward, index){
      const response = await swal({
        title: 'Hold on!',
        text: "This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Toggle'
      })
      if(!response.value){
        return
      }
      let uid = reward.uid || "";
      const currentGame = this.state.currentGame || {};
      const gameId = currentGame.id || "";
      const prizeWonId = reward.prizeWonId || "";
      if(!uid || !gameId || !prizeWonId){
        return
      }
      this.setState({
        loading: true
      })
      let valueToSet = false;
      const redeemedPrizeRef = await db.collection('prizeWon').doc(prizeWonId).get();
      const redeemedPrize = redeemedPrizeRef.data();
      if(redeemedPrize && redeemedPrize.redeemed){
        await db.collection('prizeWon').doc(prizeWonId).update({redeemed: false, timeRedeemed: null});
      } else {
        valueToSet = true;
        try {
          await db.collection('prizeWon').doc(prizeWonId).update({redeemed: true, timeRedeemed: new Date().getTime()});
        } catch (e) {
          this.setState({
            loading: false
          })
          swal({
            title: 'Hold on!',
            text: 'Prize must be sent before it can be set redeemed.',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          console.log(e)
          return
        }
      }
      const currentGameRewards = this.state.currentGameRewards;
      currentGameRewards[index].isRedeemed = valueToSet;
      this.setState({
        currentGameRewards: currentGameRewards,
        loading: false
      })
    }

    async removeGameFromFutureList(gameId){
      const result = await swal({
        title: 'STOP!',
        text: 'Are you sure you want to do this?  This will remove the future game',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'YES',
      });
      if(!result.value) return;
      this.setState({loading: true})
      await deleteCollection(db, `gamesList/${gameId}/rewards`, 100)
      // await db.collection(`gamesList`).doc(gameId).collection('rewards').delete();
      await db.collection(`gamesList`).doc(gameId).delete();
      this.setState({loading: false})
    }

    async makeFutureGameCurrent(game){
      const rewards = await db.collection('gamesList').doc(game.id).collection('rewards').get();
      const rewardsObject = {};
      rewards.forEach(function(item){
       const reward = item.data();
       rewardsObject[reward.id] = reward;
      })
      const result = await swal({
        title: 'STOP!',
        text: 'Are you sure you want to do this?  This will remove the current game and replace it with this one',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'YES',
      });
      if(!result.value) return;
      game['currentGame'] = true;
      await this.saveDeleteCurrentGameDataReplace(this.state.currentGame, game, rewardsObject)
    }

    switchWinning(showBoolean) {
      if (showBoolean) {
        document.getElementById('showWinning').classList.add('active');
        document.getElementById('showLosing').classList.remove('active');
      } else {
        document.getElementById('showWinning').classList.remove('active');
        document.getElementById('showLosing').classList.add('active');
      }
      this.setState({
        showWinning: showBoolean
      })
    }

    async resendPrize(prizeWon){
      const result = await swal({
        title: "Are you sure?",
        text: "Do you want to resend the prize email?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: 'YES'
      });
      if (result.value) {
        this.setState({loading: true});
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/resendPrizeEmail`;
        if(process.env.NODE_ENV === "development"){
          url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/resendPrizeEmail`;
        }

        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'authorization': bearerTokenString
            },
            body: JSON.stringify(prizeWon),
          });

          if (response.ok) {
            swal({
              title: 'Successfully Sent!',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            });
          } else {
            swal({
              title: 'Oh No!',
              text: "Something went wrong and the email did not resend, please check your internet and try again",
              type: 'warning',
              confirmButtonText: 'Ok'
            });
            console.log('Error: ', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetch: ', error);
        } finally {
          this.setState({loading: false});
        }
      }
    }

    render() {
      let selectedGame = this.state.currentGame || {};
      let currentGameActive = selectedGame.active || false;
      const hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions || false;
      const currentGameRewards = this.state.currentGameRewards || [];
      const currentGameRewardsList = this.state.currentGameRewardsList || [];
      const totalPlayers = this.state.players || 0;
      const otherNumber = this.state.otherNumber || 0;
      let startTimeRemake = "";
      let endTimeRemake = "";
      let showTime = false;
      var rewardsAdded = this.state.rewardsAdded || [];
      var totalAmountOfRewardsCreated = 0;
      var totalAmount = 0;
      for(var reward in rewardsAdded){
        totalAmountOfRewardsCreated = parseInt(totalAmountOfRewardsCreated) + parseInt(rewardsAdded[reward].amount)
      }
      if(parseInt(otherNumber) > 0 && parseInt(this.state.cardsPerPack) > 0 && parseInt(totalAmountOfRewardsCreated) > 0){
        totalAmount = parseInt(this.state.cardsPerPack) * parseInt(totalAmountOfRewardsCreated/(1/otherNumber))
        if(parseInt(otherNumber) === 1){
          totalAmount = totalAmountOfRewardsCreated
        }
      }
      const isMlbTeam = process.env.REACT_APP_IS_MLB_TEAM === "true";
      let showPlayerLimit = false;
      if(selectedGame.startTime && selectedGame.endTime){
        showTime = true
        if(selectedGame.startTime.indexOf("Z")===-1){
          startTimeRemake = selectedGame.startTime + "Z"
        } else {
          startTimeRemake= selectedGame.startTime
        }
        if(selectedGame.endTime.indexOf("Z")===-1){
          endTimeRemake = selectedGame.endTime + "Z"
        } else {
          endTimeRemake = selectedGame.endTime
        }
        showPlayerLimit = !!selectedGame.playerLimit && selectedGame.playerLimit > 0;
        if(new Date(endTimeRemake).getTime() <= new Date(startTimeRemake).getTime()){
          showTime = false;
        }
      }
      const vm = this;
      const hasFollowUpEmail = process.env.REACT_APP_HAS_FOLLOW_UP_EMAIL === "true";
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && currentGameActive === true ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Scratchers Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && currentGameActive === false ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Scratchers is Inactive</p>
                  </div>
                </div>
                <div className="mobile-spacer"/>
                <div className="col-md-3 col-sm-12">
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && currentGameActive === false ? '' : 'none', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && currentGameActive === true ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="mobile-spacer"/>
                <div className="mobile-spacer"/>
                <div className="col-md-6 col-sm-12">
                  <a className="btn btn-outline-danger btn-sm reset-game-button" onClick={() => this.reset()} style={{display: selectedGame ? '' : 'none'}}>Reset Game</a>
                  <button onClick={() => this.openCreateGameModal()} className="btn btn-primary btn-lg create-game-button">New Game</button>
                </div>
              </div>
            </div>
            <div className="admin-grid-container four-columns" style={{marginTop:20}}>
              {selectedGame && selectedGame.difficulty &&
                  <div className="card" >
                    <div className="card-body">
                      <h4>Game Info: </h4>
                      {selectedGame.gameName &&
                          <p>{"Game Name: " + selectedGame.gameName}</p>
                      }
                      <p>{"Odds To Win: 1/" + selectedGame.difficulty.toString()}</p>
                      {showPlayerLimit &&
                          <p>Entry Limit: {selectedGame.playerLimit.toString()}</p>
                      }
                      {selectedGame.numberOfPlaysAllowed &&
                          <p>Number of Plays Allowed: {selectedGame.numberOfPlaysAllowed.toString()}</p>
                      }
                      {selectedGame.sendFollowUpEmail &&
                          <p>Send Follow Up Email: true</p>
                      }
                      {selectedGame.rewardsPerPack &&
                          <p>Cards Per Pack: {selectedGame.rewardsPerPack.toString()}</p>
                      }
                    </div>
                  </div>
              }
              {selectedGame && currentGameRewardsList && currentGameRewardsList.length > 0 &&
                  <div className="card" >
                    <div className="card-body">
                      <h4>Game Prizes: </h4>
                      <ol>
                        {
                          currentGameRewardsList.map(function (item,index){
                            return  <li key={index}>
                              <p>{item.rewardName}, {item.noOfAvailableTickets}{item.isRedeemable && " -- Sqwad Redemption Process Enabled"}</p>
                            </li>
                          })
                        }
                      </ol>
                      {selectedGame.hasLosingReward &&
                          <p>Losing Prize: {selectedGame.losingReward.rewardName}</p>
                      }
                    </div>
                  </div>
              }
              <div className="card" style={{display: showTime ? 'block' : 'none'}}>
                <div className="card-body">
                  <h4>Scheduled Start Time</h4>
                  <p>{startTimeRemake ? convertTimeStampToHumanReadable(new Date(startTimeRemake).getTime()) : ''}</p>
                </div>
              </div>
              <div className="card" style={{display: showTime ? 'block' : 'none'}}>
                <div className="card-body">
                  <h4>Scheduled End Time </h4>
                  <p>{endTimeRemake ? convertTimeStampToHumanReadable(new Date(endTimeRemake).getTime()) : ''}</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                  <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                    <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                      <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                      <span className="d-lg-block">Stats</span>
                    </a>
                  </li>
                  <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                      <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                      <span className="d-lg-block">Prizes</span>
                    </a>
                  </li>
                  <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                      <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                      <span className="d-none d-lg-block">Future Games</span>
                    </a>
                  </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                  <div style={{color:'black', marginLeft:20, fontSize:20, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span className="mobile-hide">Reward Management</span>
                    <span className="gameBarExtraStyles">
                      {totalPlayers > 0 &&
                          <button onClick={()=> this.downloadUsers()} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                            <span className="fa fa-arrow-circle-down"/> Download Rewards
                          </button>
                      }
                      <div className="mobile-spacer"/>
                      <div className="mobile-spacer"/>
                      <div className="mobile-spacer"/>

                        <input style={{minWidth:350}} type="text" id="searchQuery" name="search" placeholder="Filter By Prize Name (Case Sensitive)" onChange={()=>{
                          const vm = this;
                          clearTimeout(this.timeoutId); // no-op if invalid id
                          this.timeoutId = setTimeout(async function(){
                            let input = document.getElementById('searchQuery').value;
                            const response = await sortPlayersForAdmin(vm.state.responseSize, input, vm.state.currentGame, db);
                            vm.setState({
                              currentGameRewards: response[0]
                            })
                          }, 500);
                        }
                        }/>
                    </span>
                  </div>
                  <div className="vertical-spacer"/>
                    <div className="table-responsive">
                      <div style={{height:700,overflow:"auto"}}>
                        <InfiniteScroll
                            loadMore={(page) => this.handleLoadMore(page)}
                            hasMore={this.state.responseSize*this.state.page === currentGameRewards.length}
                            pageStart={0}
                            useWindow={false}
                        >
                        <table className="table table-striped" style={{color:'black', marginBottom:100}}>
                          <tbody>
                          <tr>
                            <th>Reward</th>
                            <th>{isMlbTeam? "Id": "Email"}</th>
                            <th>Code</th>
                            <th>Sent Time</th>
                            {hasRewardWithAutoRedemptions &&
                                <th>Redeemed</th>
                            }
                            <th></th>
                          </tr>
                          {
                            currentGameRewards.map(function(item,i){
                              const redeemCode = item.code || "";
                              return <tr key={i}>
                                <td style={{fontFamily:'Open Sans', verticalAlign: "middle"}}>{item.rewardName}</td>
                                <td style={{fontFamily:'Open Sans', verticalAlign: "middle"}}>
                                  {item.email}
                                </td>
                                <td style={{display: redeemCode ? 'table-cell' : 'none', fontFamily:'Open Sans', verticalAlign: "middle" }}>
                                  <strong>Code:</strong> {redeemCode}
                                </td>
                                {!redeemCode &&
                                    <td style={{fontFamily:'Open Sans', verticalAlign: "middle" }}>
                                      <strong>No Code Required</strong>
                                    </td>
                                }
                                <td style={{fontFamily:'Open Sans', verticalAlign: "middle"}}>{item.emailTimeSent ? convertTimeStampToHumanReadable(item.emailTimeSent || item.emailTimeSent.seconds) : "Not Sent"}</td>
                                {hasRewardWithAutoRedemptions &&
                                    <td style={{fontFamily: 'Open Sans', verticalAlign: "middle"}}>
                                      <input style={{verticalAlign: "middle"}} type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly/>
                                      <label style={{verticalAlign: "middle"}} htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                    </td>
                                }
                                <td>
                                  <a href={`/user?user=${item.uid}`} style={{marginRight: 20, color: 'black'}}>
                                    <i style={{fontSize: 20}} className="fa fa-info-circle"/>
                                  </a>
                                  {item.rewardName &&
                                      <button className="btn btn-primary" onClick={() => this.resendPrize(item)}>
                                        Resend
                                      </button>
                                  }
                                </td>
                              </tr>
                            }, this)
                          }
                          </tbody>
                        </table>
                        </InfiniteScroll>
                      </div>
                    </div>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                      <div className="card-body">
                        <blockquote className="card-bodyquote">
                          <p>Current Game Players</p>
                          <footer className="value-text">{this.state.players}</footer>
                        </blockquote>
                      </div>
                    </div>
                    <div className="card card-styles text-xs-center">
                      <div className="card-body">
                        <blockquote className="card-bodyquote">
                          <p>Current Prizes Won</p>
                          <footer className="value-text">{this.state.prizesWon}</footer>
                        </blockquote>
                      </div>
                    </div>
                    {hasRewardWithAutoRedemptions &&
                        <div className="card card-styles text-xs-center">
                          <div className="card-body">
                            <blockquote className="card-bodyquote">
                              <p>Redemptions</p>
                              <footer className="value-text">{this.state.redemptions || 0}</footer>
                            </blockquote>
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                    <tr>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Make Current Game</th>
                      <th>Delete Game</th>
                    </tr>
                    {
                      this.state.futureGamesList.map(function(item,i){
                        const dateTime = convertTimeStampToHumanReadable(new Date(item.startTime).getTime())
                        return <tr key={i} style={{verticalAlign: 'middle'}}>
                          <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                          <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                          <td style={{fontFamily:'Open Sans' }}><button style={{visibility: i !== 0 && "hidden"}} className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                          <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                        </tr>
                      }, this)
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3"> Create Game</h4>
                <div id="rootwizard">
                  <ul className="nav nav-pills bg-dark-light nav-justified mb-3">
                    <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                      <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                        <span className="fa fa-pencil-square-o"/>
                        <span className="d-none d-sm-inline"> The Basics</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                      <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                        <span className="fa fa-trophy"/>
                        <span className="d-none d-sm-inline"> Add Prizes</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                      <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                        <span className="fa fa-sign-out"/>
                        <span className="d-none d-sm-inline"> Set Difficulty</span>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                    <div className="tab-pane active show" id="first">
                      <form id="accountForm" className="form-horizontal">
                        <div className="row">
                          <div className="col-12">
                            <div className="mb-3">
                              <label style={{marginBottom: 0}} htmlFor="gameName">Game Name</label>
                              <p style={{marginBottom: 0, fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>An identifier to help you identify the game</p>
                              <input id="gameName" name="gameName" className="form-control" value={this.state.gameName} onChange={this.handleChange} />
                            </div>
                            <div className="mb-3">
                              <label className="col-md-3 col-form-label" htmlFor="userName3">Set Auto Start/End Time?</label>
                              <label className="switch" style={{marginTop:'10px'}}>
                                <input type="checkbox" onClick={this.toggleAutoGame} checked={this.state.autoGame}/>
                                <span className="slider round"/>
                              </label>
                            </div>
                            <style dangerouslySetInnerHTML={{
                              __html: [
                                '.react-datepicker-time__input {',
                                'width: 100% !important;',
                                '}'
                              ].join('\n')
                            }}/>
                            <div className="form-group row mb-3 form_times" style={{display: this.state.autoGame? '':'none'}}>
                              <label className="col-md-3 col-form-label" htmlFor="password3"> Game Start Time</label>
                              <div className="col-md-9">
                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.gameStartTime} onChange={date => this.setState({gameStartTime: date})}/>
                              </div>
                            </div>

                            <div className="form-group row mb-3 form_times" style={{display: this.state.autoGame? '':'none'}}>
                              <label className="col-md-3 col-form-label" htmlFor="confirm3"> Game End Time</label>
                              <div className="col-md-9">
                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.gameEndTime} onChange={date => this.setState({gameEndTime: date})}/>
                              </div>
                            </div>

                            <div className="checkbox" style={{display: 'none'}}>
                              <label>
                                Allow Multiple Plays (Every 24 hrs) <input value={this.state.allowMoreScratchersAfterTime} name="allowMoreScratchersAfterTime" type="checkbox" checked={this.state.allowMoreScratchersAfterTime} onChange={this.handleChange} />
                              </label>
                            </div>

                            <div className="checkbox" style={{display: 'none'}}>
                              <label>
                                Limit Prizes Given Out In 24 hrs <input value={this.state.limitPrizesGivenOut} name="limitPrizesGivenOut" type="checkbox" checked={this.state.limitPrizesGivenOut} onChange={this.handleChange} />
                              </label>
                            </div>

                            {this.state.limitPrizesGivenOut &&
                                <div className="form-group row mb-3" style={{display: 'none'}}>
                                  <label className="col-md-3 col-form-label" htmlFor="surname3">Participant Limit:</label>
                                  <div className="col-md-9">
                                    <input id="prizeLimit" name="prizeLimit" type="number" className="form-control" value={this.state.prizeLimit} onChange={this.handleChange} placeholder="5" />
                                  </div>
                                </div>
                            }

                            <div className="checkbox">
                              <label>
                                Limit Players <input value={this.state.addPlayerLimits} name="addPlayerLimits" type="checkbox" checked={this.state.addPlayerLimits} onChange={this.handleChange} />
                              </label>
                            </div>
                            {this.state.addPlayerLimits &&
                                <div className="form-group row mb-3">
                                  <label className="col-md-3 col-form-label" htmlFor="surname3">Player Limit:</label>
                                  <div className="col-md-9">
                                    <input id="playerLimit" name="playerLimit" type="number" className="form-control" value={this.state.playerLimit} onChange={this.handleChange} placeholder="5" />
                                  </div>
                                </div>
                            }

                            <div className="checkbox">
                              <label>
                                Allow Multiple Plays <input value={this.state.allowMultiplePlays} name="allowMultiplePlays" type="checkbox" checked={this.state.allowMultiplePlays} onChange={this.handleChange} />
                              </label>
                            </div>

                            {this.state.allowMultiplePlays &&
                                <>
                                  <div className="form-group row mb-3">
                                    <label className="col-md-3 col-form-label" htmlFor="surname3">Number Of Plays Allowed:</label>
                                    <div className="col-md-9">
                                      <input id="numberOfPlaysAllowed" name="numberOfPlaysAllowed" type="number" className="form-control" value={this.state.numberOfPlaysAllowed} onChange={this.handleChange} placeholder="1" />
                                    </div>
                                  </div>
                                  <div className="form-group row mb-3">
                                    <label className="col-md-3 col-form-label" htmlFor="surname3">Number Of Hours Between Plays:</label>
                                    <div className="col-md-9">
                                      <input id="numberOfHoursBetweenPlays" name="numberOfHoursBetweenPlays" type="number" className="form-control" value={this.state.numberOfHoursBetweenPlays} onChange={this.handleChange} placeholder="1.5" />
                                    </div>
                                  </div>
                                </>
                            }

                            <div className="form-group row mb-3">
                              <label className="col-md-3 col-form-label" htmlFor="confirm3"> Cards Per Pack</label>
                              <div className="col-md-9">
                                <input id="cardsPerPack" name="cardsPerPack" type="number" className="form-control" value={this.state.cardsPerPack} onChange={this.handleChange} required=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="tab-pane" id="second">
                      <div className="form-horizontal">
                        {hasFollowUpEmail &&
                            <div className="checkbox">
                              <label>
                                Send Follow Up Email? <input value={this.state.sendFollowUpEmail} name="sendFollowUpEmail" type="checkbox" checked={this.state.sendFollowUpEmail} onChange={this.handleChange} />
                              </label>
                            </div>
                        }
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.switchWinning(true)}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showWinning">
                              <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                              <span className="d-none d-lg-block">Winning</span>
                            </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.switchWinning(false)}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showLosing">
                              <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                              <span className="d-none d-lg-block">Losing</span>
                            </a>
                          </li>
                        </ul>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row mb-3">
                              <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                              <div className="col-md-9">
                                {this.state.showWinning &&
                                  <select className="form-control" onChange={this.saveReward}>
                                    <option/>
                                    {
                                      this.state.ticketList.map(function(item,index){
                                        if(!item.isWinning) return;
                                        return(
                                            <option value={item.id} key={index}>{item.rewardName}</option>
                                        )
                                      })
                                    }
                                  </select>
                                }
                                {!this.state.showWinning &&
                                    <select value={this.state.losingRewardToAdd} className="form-control" onChange={(evt) => this.setState({ losingRewardToAdd: evt.target.value })}>
                                      <option/>
                                      {
                                        this.state.ticketList.map(function(item,index){
                                          if(!item.isWinning) return;
                                          return(
                                              <option value={item.id} key={index}>{item.rewardName}</option>
                                          )
                                        })
                                      }
                                    </select>
                                }
                              </div>
                            </div>
                            {this.state.showWinning &&
                              <>
                                <div className="form-group row mb-3">
                                  <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                  <div className="col-md-9">
                                    <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                  </div>
                                </div>

                                <div className="checkbox">
                                  <label>
                                    Redeem Codes? <input value={this.state.codes} name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                  </label>
                                </div>

                                <div className="checkbox" style={{display: this.state.codes ? 'flex' : 'none'}}>
                                  <label>
                                    Convert Codes To QRCode? <input value={this.state.convertToQrCode} name="convertToQrCode" type="checkbox" checked={this.state.convertToQrCode} onChange={this.handleChange} />
                                  </label>
                                </div>

                                <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                  <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                  <div className="col-md-9">
                                    <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                  </div>
                                </div>

                                <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none'}}>
                                  <label htmlFor="pinsArray" className="col-md-3 col-form-label">Enter Corresponding Pins (OPTIONAL)</label>
                                  <div className="col-md-9">
                                    <textarea value={this.state.pinsArray} className="form-control" name="pinsArray" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                  </div>
                                </div>

                                <div className="form-group row mb-3" align="center">
                                  <div className="col-md-12">
                                    <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                  </div>
                                </div>

                                <div className="form-group mb-3">
                                  <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                    {
                                      rewardsAdded.map(function(item,index){
                                        var codesText = "No"
                                        if(item.codes){
                                          codesText = "Yes"
                                          if(item.convertToQrCode){
                                            codesText = "Yes; QrCodes: Yes"
                                          } else if(!isMlbTeam) {
                                            codesText = codesText + "; QrCodes: No";
                                          }
                                        }
                                        return(
                                            <div key={index} className="form-group">
                                              <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index, "rewardsAdded")}/></li>
                                            </div>
                                        )
                                      })
                                    }
                                  </ol>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="third">
                      <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                      <div className="row">
                        <div className="col-12">
                          <div className="text-center">
                            <h2 className="mt-0">
                              <i className="mdi mdi-check-all"/>
                            </h2>
                            <h3 className="mt-0">How easy do you want to make it to win prizes?</h3>

                            <p className="w-75 mb-2 mx-auto" style={{fontFamily:'Open Sans'}}>Select Difficulty</p>

                            <div className="row form-group">
                              {/*<div className="col-md-2 col-sm-12">
                                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="ogOdds" onClick={()=>this.calculateOdds("original")}>
                                                  <p>Original</p>
                                                  <p>Keep the odds the same as before</p>
                                                </button>
                                              </div>*/}
                              <div className="col-md-3 col-sm-12">
                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="easyOdds" onClick={()=>this.calculateOdds("easy")}>
                                  <p>Easy</p>
                                  <p>About 1/3 players win</p>
                                </button>
                              </div>
                              <div className="mobile-spacer"/>
                              <div className="col-md-3 col-sm-12">
                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="mediumOdds" onClick={()=>this.calculateOdds("medium")}>
                                  <p>Medium</p>
                                  <p>About 1/30 players win</p>
                                </button>
                              </div>
                              <div className="mobile-spacer"/>
                              <div className="col-md-3 col-sm-12">
                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="hardOdds" onClick={()=>this.calculateOdds("hard")}>
                                  <p>Hard</p>
                                  <p>About 1/300 players win</p>
                                </button>
                              </div>
                              <div className="mobile-spacer"/>
                              <div className="col-md-3 col-sm-12">
                                <center className="mobile-border-other">
                                  <label className="form-label" htmlFor="otherNumber">Other Amount: 1/</label>
                                  <input style={{border:"1px solid black"}} value={this.state.otherNumber} name="otherNumber" type="number" min="0" onChange={this.handleChange}/>
                                  <p>Other Total Tickets Created: {totalAmount}</p>
                                </center>
                                {/*<button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="zeroOdds" onClick={()=>this.calculateOdds("other")}>
                                                  <p>Everyone Wins</p>
                                                  <p>All prizes are given out before losers are sent</p>
                                                </button>*/}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>

                    <ul className="list-inline wizard mb-0">
                      <li className="next list-inline-item">
                        <a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.toggle()} style={{display: this.state.wizardLocation === 'first' ? '' : 'none' }}>
                          Close
                        </a>
                      </li>
                      <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}>
                        <a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>
                          Previous
                        </a>
                      </li>
                      <li className="next list-inline-item float-end">
                        <a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>
                          Next
                        </a>
                      </li>
                      <li className="next list-inline-item float-end">
                        <a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>
                          Create Game
                        </a>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </Modal>
       </div>
      );
    }
}
